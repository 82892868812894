<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>{{ $i18n.translate("Promotions") }}</v-toolbar-title>
        <v-spacer />
        <v-form @submit.stop.prevent="onSubmit">
          <v-text-field
            class="mr-4"
            v-model="search"
            :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Promotions')"
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            @click:clear="onClear"
          />
        </v-form>

        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on: onMenu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn class="ml-2" icon v-on="{ ...onMenu, ...onTooltip }">
                  <v-icon>mdi-magnify-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Advanced Search") }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item-group color="primary">
              <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
              <v-list-item selectable>
                <v-list-item-content>
                  <v-select
                    dense
                    :items="[
                      { text: 'Active', value: true },
                      { text: 'Inactive', value: false }
                    ]"
                    v-model="filterBySubmissionEffective"
                    :label="$i18n.translate('Submission Effective')"
                    clearable
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <v-select
                    dense
                    :items="effectiveItems"
                    v-model="filterByEffective"
                    :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                    clearable
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <PromotionTypesField
                    dense
                    v-model="filterByPromotionTypes"
                    :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Promotion Types')"
                    @input="onSubmit"
                    @loaded="filterByPromotionTypes = []"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <ClaimTypesField
                    dense
                    v-model="filterByClaimTypes"
                    :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Claim Types')"
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <ParticipantTypeField
                    dense
                    v-model="filterByParticipantType"
                    :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Participant Type')"
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <ParticipantGroupField
                    dense
                    v-model="filterByParticipantGroup"
                    :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Participant Group')"
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <OrganizationTypeField
                    dense
                    v-model="filterByOrganizationType"
                    :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Organization Type')"
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item selectable>
                <v-list-item-content>
                  <OrganizationGroupField
                    dense
                    v-model="filterByOrganizationGroup"
                    :label="filterByOrganizationGroupLabel"
                    @input="onSubmit"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-tooltip bottom v-if="hasFilters">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn class="ml-2" icon v-on="{ ...onTooltip }" @click="onClearSearch">
              <v-icon>mdi-magnify-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Clear Search") }}</span>
        </v-tooltip>
        <v-btn
          v-if="$privilege.hasPrivilege('PROMOTION_CREATE')"
          class="ml-2"
          fab
          small
          :to="{ name: 'promotion', params: { id: 0 } }"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :items="items"
          :headers="headers"
          :server-items-length="total"
          :options.sync="options"
          :loading="loading"
        >
          <template v-slot:item.promotionKey="{ item }">
            <router-link :to="{ name: 'promotion', params: { id: item.id } }">
              {{ item.promotionKey }}
            </router-link>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item.promotionType="{ item }">
            {{ item.promotionType.name }} ({{ item.promotionType.inputType.description }})
          </template>
          <template v-slot:item.effective="{ item }">
            <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
            <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
          </template>
          <template v-slot:item.submissionsActive="{ item }">
            <v-chip color="primary" v-if="item.submissionsActive">{{ $i18n.translate("Active") }}</v-chip>
            <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
          </template>
          <template v-slot:item.payoutStrategyType="{ item }">
            {{ item.payoutStrategyType ? item.payoutStrategyType.description : "" }}
          </template>
          <template v-slot:item.invitationOnly="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn icon v-on="{ ...onTooltip }">
                  <v-icon color="primary" v-if="item.invitationOnly">mdi-lock</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Invite Only") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.effectiveDate="{ item }">
            {{ item.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
          </template>
          <template v-slot:item.expirationDate="{ item }">
            {{ item.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
          </template>
          <template v-slot:item.updatedDate="{ item }">
            {{ item.updatedDate | formatDateFromNow }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="$privilege.hasPrivilege('PROMOTION_DELETE')" color="error" @click="openDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ itemToDelete?.promotionKey }}
        </v-card-title>

        <v-card-text>
          {{ $i18n.translate("Are you sure you want to delete the promotion:") }}
          <strong>{{ itemToDelete?.promotionKey }}</strong
          >?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancleDetelete">{{ $i18n.translate("Cancel") }}</v-btn>
          <v-btn color="primary" @click="confirmDelete">{{ $i18n.translate("Delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" multi-line :timeout="2000" :color="snackbar.color" right bottom>
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import ClaimTypesField from "@/gapp-components/components/fields/ClaimTypesField.vue";
import OrganizationGroupField from "@/gapp-components/components/fields/OrganizationGroupField.vue";
import OrganizationTypeField from "@/gapp-components/components/fields/OrganizationTypeField.vue";
import ParticipantGroupField from "@/gapp-components/components/fields/ParticipantGroupField.vue";
import ParticipantTypeField from "@/gapp-components/components/fields/ParticipantTypeField.vue";
import PromotionTypesField from "@/gapp-components/components/fields/PromotionTypesField.vue";
import { mapGetters } from "vuex";

export default {
  name: "Promotions",
  components: {
    PromotionTypesField,
    ClaimTypesField,
    ParticipantTypeField,
    ParticipantGroupField,
    OrganizationTypeField,
    OrganizationGroupField
  },
  metaInfo: {
    title: "Promotions"
  },
  data() {
    return {
      search: "",
      filterByEffective: "Active",
      filterBySubmissionEffective: true,
      filterByPromotionTypes: [],
      filterByClaimTypes: [],
      filterByOrganizationType: undefined,
      filterByOrganizationGroup: undefined,
      filterByParticipantType: undefined,
      filterByParticipantGroup: undefined,

      loading: false,
      items: [],
      nameFilter: "",
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },

      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      deleteDialog: false,
      itemToDelete: null,
      snackbar: {
        show: false,
        text: "",
        color: ""
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.$api
        .delete(`/api/promotions/${this.itemToDelete.id}/delete`)
        .then(() => {
          this.getData();
          this.snackbar.text = this.$i18n.translate("Item deleted successfully");
          this.snackbar.color = "success";
        })
        .catch(error => {
          console.log(error);
          this.snackbar.text = error.response?.data?.errorMessage || "Something went wrong";
          this.snackbar.color = "error";
        })
        .finally(() => {
          this.deleteDialog = false;
          this.itemToDelete = null;
          this.snackbar.show = true;
        });
    },
    cancleDetelete() {
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByEffective = "All";
      this.filterBySubmissionEffective = undefined;
      this.filterByPromotionTypes = [];
      this.filterByClaimTypes = [];
      this.filterByOrganizationType = undefined;
      this.filterByOrganizationGroup = undefined;
      this.filterByParticipantType = undefined;
      this.filterByParticipantGroup = undefined;
      this.onSubmit();
    },
    fetchPromotionType(key) {
      return this.$api.post("/api/promotionTypes/search?size=1", { promotionTypeKey: key }).then(({ data }) => {
        this.filterByPromotionTypes = data && data.content.length == 1 ? data.content[0] : null;
      });
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.filterBySubmissionEffective != undefined) {
        filters.isSubmissionsEffective = this.filterBySubmissionEffective;
      }

      if (!this.filterByEffective) {
        this.filterByEffective = "All";
      }
      filters.isEffective = this.filterByEffective;

      if (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) {
        filters.promotionTypes = this.filterByPromotionTypes.map(promotionType => {
          return {
            id: promotionType.id
          };
        });
      }
      if (this.filterByClaimTypes && this.filterByClaimTypes.length > 0) {
        filters.claimTypes = this.filterByClaimTypes.map(claimType => {
          return {
            id: claimType.id
          };
        });
      }
      if (this.filterByParticipantType) {
        filters.participantType = { id: this.filterByParticipantType.id };
      }
      if (this.filterByParticipantGroup) {
        filters.participantGroup = { id: this.filterByParticipantGroup.id };
      }
      if (this.filterByOrganizationType) {
        filters.organizationType = { id: this.filterByOrganizationType.id };
      }
      if (this.filterByOrganizationGroup) {
        filters.organizationGroup = { id: this.filterByOrganizationGroup.id };
      }

      this.$api
        .post(
          "/api/promotions/search/simple?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    if (this.$route.query.promotionTypeKey != undefined) {
      this.fetchPromotionType(this.$route.query.promotionTypeKey).then(promotionType => {
        if (promotionType) {
          this.filterByPromotionTypes.push(promotionType);
        }
        this.onSubmit();
      });
    } else {
      this.onSubmit();
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: this.$i18n.translate("Clients"),
        to: { name: "clients" },
        exact: true
      },
      {
        text: this.selectedClient.name,
        to: {
          name: "client",
          params: { id: this.selectedClient.id },
          exact: true
        }
      },
      {
        text: this.$i18n.translate("Programs"),
        to: { name: "programs" },
        exact: true
      },
      {
        text: this.selectedProgram.name,
        to: {
          name: "program",
          params: { id: this.selectedProgram.id },
          exact: true
        }
      },
      { text: "Promotions" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        this.filterByEffective != undefined ||
        this.filterBySubmissionEffective != undefined ||
        (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) ||
        (this.filterByClaimTypes && this.filterByClaimTypes.length > 0) ||
        this.filterByParticipantType != undefined ||
        this.filterByParticipantGroup != undefined ||
        this.filterByOrganizationType != undefined ||
        this.filterByOrganizationGroup != undefined;
      return check;
    },
    filterByOrganizationGroupLabel() {
      return "Filter by " + this.$i18n.translate("Organization Group");
    },
    headers() {
      let h = [
        {
          value: "promotionKey",
          text: this.$i18n.translate("Promotion Key"),
          sortable: true
        },
        {
          value: "name",
          text: this.$i18n.translate("Promotion Name"),
          sortable: true
        },
        {
          value: "promotionType",
          text: this.$i18n.translate("Promotion Type"),
          sortable: true
        },
        {
          value: "payoutStrategyType",
          text: this.$i18n.translate("Payout Strategy"),
          sortable: true
        },
        {
          value: "invitationOnly",
          text: this.$i18n.translate("Invite Only?"),
          sortable: true,
          align: "center"
        },
        {
          value: "effectiveDate",
          text: this.$i18n.translate("Effective Date"),
          sortable: true
        },
        {
          value: "expirationDate",
          text: this.$i18n.translate("Expiration Date"),
          sortable: true
        },
        {
          value: "country.name",
          text: this.$i18n.translate("Country"),
          sortable: true,
          align: "center"
        },
        {
          value: "effective",
          text: "Status",
          sortable: true,
          align: "center"
        },
        {
          value: "submissionsActive",
          text: "Submission Status",
          sortable: false,
          align: "center"
        },
        {
          value: "updatedDate",
          text: this.$i18n.translate("Last Updated"),
          sortable: true
        }
      ];
      if (this.$privilege.hasPrivilege("PROMOTION_DELETE")) {
        h.push({
          value: "action",
          text: this.$i18n.translate("Action"),
          align: "center"
        });
      }
      return h;
    }
  }
};
</script>
